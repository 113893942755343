import { subsidySchema, SubsidyValues } from '../../RightDrawerSchema';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Grid, Box } from '@mui/material';
import { OptionItem, SubsidyRecipientView } from '../../../../../../generated/ApiClient';
import { FormDropdown } from '../../../../../../component/form/FormDropdown';
import i18n from '../../../../../../common/i18n/i18n';
import { FormNumberInput } from '../../../../../../component/form/FormNumberInput';
import { FormCheckbox } from '../../../../../../component/form/FormCheckbox';

interface Props {
  onSubmit: (values: SubsidyValues) => unknown;
  givers: OptionItem[];
  recipients: SubsidyRecipientView[];
  subsidies: SubsidyValues[];
}

const mapRecipientsToOI = (srv: SubsidyRecipientView): OptionItem =>
  new OptionItem({
    label: i18n.t([
      `deals.new.financesConfiguration.calculationDrawer.subsidyValues.${srv.value}`,
      srv.name,
    ]),
    value: srv.value,
  });

  const residualValueSubsidy = 19;
  const residualValueIncrease = 20;

  const subsidyRecipientMapping : {[key: number]: number[]} = {
    [residualValueSubsidy]: [residualValueIncrease],
    [residualValueIncrease]: [residualValueSubsidy]
  }

export const SubsidyForm = ({ onSubmit, givers, recipients, subsidies }: Props) => {
  const { t } = useTranslation();

  const [unit, setUnit] = useState<string>('');
  const [isFixValueAvailable, setIsFixValueAvailable] = useState<boolean>(true);
  const [isOnlyFixedValue, setIsOnlyFixedValue] = useState<boolean>(true);
  const [visibleGivers, setVisibleGivers] = useState<OptionItem[]>(givers);
  const [visibleRecipients, setVisibleRecipients] = useState<OptionItem[]>(
    recipients.map(mapRecipientsToOI)
  );

  const { control, reset, setValue, handleSubmit } = useForm<SubsidyValues>({
    resolver: zodResolver(subsidySchema),
    mode: 'onChange',
    defaultValues: {
      isFixValue: false,
      isVisible: true
    },
  });

  const subsidyRecId = useWatch({ control, name: 'subsidyRecipientId' });
  const subsidyGivId = useWatch({ control, name: 'subsidyGiverId' });

  const onHandleSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit((values) => {
      if (values.isFixValue) {
        onSubmit({
          ...values,
          fixum: values.fixum != null ? values.fixum * -1 : undefined,
          percentage: 100,
        });
      } else {
        onSubmit(values);
      }
      reset({
        isFixValue: false,
        subsidyRecipientId: -1,
        subsidyGiverId: -1,
        fixum: null,
        percentage: null,
        isVisible: true
      });
    }, console.error)(event);
  };

  const handleRecipientChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const recipient = recipients.find((r) => r.value === Number(event.target.value));
    if (recipient) {
      setIsFixValueAvailable(recipient.isFixValueAvailable);
      if (!recipient.isFixValueAvailable) {
        setValue('isFixValue', false);
      }
      setIsOnlyFixedValue(recipient.isOnlyFixedValue);
      if (recipient.isOnlyFixedValue) {
        setValue('isFixValue', true);
      }
      setUnit(
        i18n.t([
          `deals.new.financesConfiguration.calculationDrawer.subsidyUnits.${recipient.unit}`,
          recipient.unit,
        ])
      );
    } else {
      setUnit('');
    }
  };

  useEffect(() => {
    const relatedRecipients = subsidyRecId && subsidyRecipientMapping[subsidyRecId] ? subsidyRecipientMapping[subsidyRecId] : [];
    const selRecExistingGiver =
      subsidyRecId != null
        ? subsidies
            .filter((s) => s.subsidyRecipientId === subsidyRecId || relatedRecipients.includes(s.subsidyRecipientId))
            .map((s) => s.subsidyGiverId)
        : [];
      setVisibleGivers(givers.filter((g) => !selRecExistingGiver.includes(g.value)));
    // eslint-disable-next-line
  }, [subsidyRecId, subsidies, givers]);

  useEffect(() => {
    const selGiverExistingRec =
      subsidyGivId != null
        ? subsidies
            .filter((s) => s.subsidyGiverId === subsidyGivId)
            .map((s) => s.subsidyRecipientId)
        : [];
    setVisibleRecipients(
      recipients
        .filter((r) => {
          const isRecipientUsed = selGiverExistingRec.includes(r.value);
          const relatedRecipients = subsidyRecipientMapping[r.value] ?? [];
          const isRelatedRecipientUsed = relatedRecipients.some(x => selGiverExistingRec.includes(x));
          
          return !isRecipientUsed && !isRelatedRecipientUsed;
        })
        .map(mapRecipientsToOI)
    );
  }, [subsidyGivId, subsidies, recipients]);

  // @ts-ignore
  return (
    <form id="sales-aid-subsidy" onSubmit={onHandleSubmit}>
      <Grid container sx={{ width: '100%', p: 1 }} spacing={1}>
        <Grid item xs={2}>
          <FormDropdown
            control={control}
            options={visibleGivers}
            label={t('deals.new.financesConfiguration.calculationDrawer.salesAids.subsidyGiver')}
            name="subsidyGiverId"
            required
          />
        </Grid>

        <Grid item xs={4}>
          <FormDropdown
            control={control}
            options={visibleRecipients}
            onChange={handleRecipientChange}
            label={t(
              'deals.new.financesConfiguration.calculationDrawer.salesAids.subsidyRecipient'
            )}
            name="subsidyRecipientId"
            required
          />
        </Grid>

        <Grid item xs={4} spacing={1} container>
          <Grid item xs={6}>
            <FormNumberInput
              control={control}
              label={t('deals.new.financesConfiguration.calculationDrawer.salesAids.percentage')}
              name="percentage"
              fixedDecimalScale
              endAdornment={<span>%</span>}
              disabled={useWatch({ control, name: 'isFixValue' }) || subsidyRecId === 2}
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumberInput
              control={control}
              label={t('deals.new.financesConfiguration.calculationDrawer.salesAids.fixum')}
              name="fixum"
              fixedDecimalScale
              scale={subsidyRecId === 1 ? 3 : 2}
              endAdornment={<span>{unit}</span>}
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <FormCheckbox
            control={control}
            label={t('deals.new.financesConfiguration.calculationDrawer.salesAids.isFixValue')}
            name="isFixValue"
            disabled={!isFixValueAvailable || isOnlyFixedValue}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="end" width="100%">
        <Button
          type="submit"
          form="sales-aid-subsidy"
          sx={{ background: 'black' }}
          variant="contained"
        >
          {t('deals.new.financesConfiguration.calculationDrawer.salesAids.newSubsidy')}
        </Button>
      </Box>
    </form>
  );
};
